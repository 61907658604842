import React from 'react';
import Layout from "../components/layout"
import PitchtoUS from '../components/pitch-to-us';

export default function PitchtousPage({ location }) {
    return (
        <Layout location={location}>
            <PitchtoUS  />
        </Layout>
    )
}